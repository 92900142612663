import usuarioLoginStore from 'src/store/usuario-login'
import Header from 'src/components/header.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import AgendamentoModel from 'src/model/usuario/AgendamentoModel'
import LocalModel from 'src/model/usuario/LocalModel'
import vTopFilters from 'src/core/filters/vTopFilters'
import { EventBus } from 'src/core/event/EventBus'
import { LocalStorage, debounce, clone } from 'quasar'

export default {
  name: 'PainelLogadoLayout',
  mixins: [vTopFilters, NotificacaoMixin],
  components: { Header },
  data () {
    return {
      modelAgendamento: new AgendamentoModel(),
      buscadoAgendamento: false,

      modelLocal: new LocalModel(),
      carregandoLocal: false,
      carregando: true,
      locais: [],

      classAtivo: 'ativo',

      forcerRender: 1,

      delivery: null,
      videochamada: null,
      testeDrive: null,

      // Layout Main
      busca_geral: '',
      slideBusca: false,

      // Painel Logado
      menusFull: [
        {
          titulo: 'Completar Proposta',
          descricao: 'Continue o processo de compra completando algumas informações.',
          icone: 'app:painel_completar_proposta',
          rota: 'painel-proposta'
        },
        {
          titulo: 'Agendar Entrega',
          descricao: 'Você está pronto para uma nova experiência de entrega?',
          icone: 'app:painel_agendar_delivery',
          rota: 'painel-delivery',
          tipo: 'Delivery'
        },
        {
          titulo: 'Agendar Teste Drive',
          descricao: 'Aqui podemos entregar o carro ou você vem até nós.',
          icone: 'app:painel_agendar_test_drive',
          rota: 'painel-test-drive',
          tipo: 'Teste Drive'
        },
        {
          titulo: 'Agendar Videochamada',
          descricao: 'Está pronto para conhecer o carro via videochamadas? Vamos nos falar?',
          icone: 'app:painel_agendar_videochamada',
          rota: 'painel-videochamada',
          tipo: 'Videochamada'
        }
      ],
      menus: [{
        titulo: 'Completar Proposta',
        descricao: 'Continue o processo de compra completando algumas informações.',
        icone: 'app:painel_completar_proposta',
        rota: 'painel-proposta'
      }],
      /* */

      modelElastic: new ElasticSearchModel(),
      dados: null,
      anuncio: {
        foto: null,
        bem_arquivos: [],
        cor_objeto: {
          cor_nome: null
        },
        cambio_objeto: {
          cambio_nome: null
        },
        combustivel_objeto: {
          combustivel_nome: null
        },
        bem: {
          versao: {
            nome: null,
            modelo: {
              nome: null,
              marca: {
                id: null,
                nome: null
              }
            }
          }
        }
      }
    }
  },
  created () {
    this.menus = [clone(this.menusFull[0])]
  },
  beforeCreate () {
    if (!this.$store.hasModule('usuario-login')) {
      this.$store.registerModule('usuario-login', usuarioLoginStore)
    }
  },
  computed: {
    valorVeiculoComp () {
      if (this.pcd) {
        return this.dados ? this.dados.valor_venda_pcd ?? this.dados.valor_venda_pf ?? 0 : 0
      }
      if (this.pj) {
        return this.dados ? this.dados.valor_venda_pj ?? this.dados.valor_venda_pf ?? 0 : 0
      }
      return this.dados ? (this.dados.valor_venda_pf && this.dados.valor_venda_pf > 0 ? this.dados.valor_venda_pf : 0) : 0
    },
    classeLayout () {
      if (this.carregando === true || this.$route.name !== 'painel-inicio') {
        setTimeout(() => {
          this.carregando = false
        }, 1200)
      } else {
        this.carregando = true
      }
      return this.$route.name === 'painel-inicio' ? 'col-0' : 'col-xs-12 col-sm-3 col-md-4 col-lg-4 col-xl-3 q-pa-lg'
    },
    arquivosUrl () {
      return process.env.arquivosUrl
    },
    anuncioId () {
      return this.$route.params.id
    },
    usuarioLogado () {
      return this.$store.getters['usuario-login/getNome'] ?? null
    },
    foto () {
      let fotoAnuncio = this.anuncio.foto.split('.')
      let teste = fotoAnuncio[fotoAnuncio.length - 1]
      delete fotoAnuncio[fotoAnuncio.length - 1]
      return fotoAnuncio.join('.') + '_original.' + teste
    },
    logado: {
      get () {
        return this.$store.getters['usuario-login/get'] ?? null
      }
    },
    usuarioSelecionado: {
      get () {
        return this.$store.getters['usuario-login/get'] ?? null
      }
    }
  },
  watch: {
    busca_geral: {
      handler: debounce(function () {
        this.atualizarBusca()
      }, 3000),
      deep: true
      // handler: debounce(function () {
      //   if (window.location.href.indexOf('comprar') > -1) {
      //     EventBus.$emit('busca_geral', this.busca_geral)
      //   } else {
      //     window.location.href = '/comprar?busca_geral=' + this.busca_geral
      //   }
      // }, 3000),
      // deep: true
    },
    anuncioId (agora, antes) {
      if (agora !== antes && agora) {
        this.getAnuncio()
      }
    },
    '$route.name' (antes, depois) {
      this.classAtivo = 'ativo'
      if (this.anuncioId) {
        if (!this.anuncio) this.getAnuncio()

        if (['painel-delivery', 'painel-test-drive', 'painel-videochamada', 'painel-proposta', 'painel-proposta-editar', 'painel-agendamento-inicio'].indexOf(this.$route.name) >= 0 && !this.buscadoAgendamento) {
          this.getAgendamentos()
        }

        if (['painel-delivery', 'painel-test-drive'].indexOf(this.$route.name) >= 0 && this.locais.length === 0) {
          this.getLocais()
        }

        if (this.$route.name === 'painel-proposta' && this.$route.params.propostaid) {
          const _params = this.$route.params
          this.$router.push({ name: 'painel-proposta-editar', params: _params })
        }
        if (this.$route.name !== 'painel-proposta-editar') {
          if (this.menus.length <= 1) {
            this.menus = [clone(this.menusFull)]
          }
        } else {
          this.menus = [clone(this.menusFull[0])]
          if (!['painel-proposta-editar', 'painel-proposta'].indexOf(this.$route.name) < 0) {
            this.menus = [clone(this.menusFull[0])]
          }
        }
      }
      // if ([0]) {
      //   this.menus[0].titulo = 'Completar Proposta'
      //   this.menus[0].descricao = 'Continue o processo de compra completando algumas informações.'
      //   this.classAtivo = 'ativo'
      // }
    }
  },
  mounted () {
    if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
      if (LocalStorage.has('usuarioSelecionado')) {
        this.usuarioSelecionado = LocalStorage.getItem('usuarioSelecionado')
      }
    }
    if (this.$route.params.publico === 'pcd') {
      this.pcd = true
    }
    if (this.$route.params.publico === 'pj') {
      this.pj = true
    }
    this.$store.dispatch('usuario-login/renovarUsuario')

    if (this.anuncioId) {
      this.getAnuncio()

      if (['painel-delivery', 'painel-test-drive', 'painel-videochamada', 'painel-proposta', 'painel-proposta-editar', 'painel-agendamento-inicio'].indexOf(this.$route.name) >= 0) {
        this.getAgendamentos()
      }

      if (['painel-delivery', 'painel-test-drive'].indexOf(this.$route.name) >= 0) {
        this.getLocais()
      }
    }
  },
  methods: {
    getLocais () {
      this.carregandoLocal = true

      this.modelLocal.getListagem().then((res) => {
        this.locais = res.dados ?? []
        this.carregandoLocal = false
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
        this.carregandoLocal = false
      })
    },

    atualizarBusca () {
      if (window.location.href.indexOf('comprar') > -1) {
        EventBus.$emit('busca_geral', this.busca_geral)
      } else {
        // this.$router.push('comprar',param)
        this.$router.push({ name: 'comprar', query: { busca_geral: this.busca_geral } })
        // window.location.href = '/comprar?busca_geral=' + this.busca_geral
      }
    },
    propostaTitulo () {
      this.menus = clone(this.menusFull)
      this.menus[0].titulo = 'Proposta Enviada'
      this.menus[0].descricao = 'Proposta enviada com sucesso'
      this.classAtivo = 'fim'
      this.getAgendamentos()
    },
    getAnuncio () {
      console.log(this.anuncioId)
      this.modelElastic
        .buscarAnuncio(this.anuncioId)
        .then(res => {
          this.anuncio =
            res.hits.hits && res.hits.hits.length > 0
              ? res.hits.hits[0]._source
              : null
          console.log('vamos', this.anuncio)
          this.dados = this.anuncio
          this.$store.commit('depois/putData', this.anuncio)
          this.$store.commit('data/putData', this.anuncio)
          this.anuncio.foto =
            this.anuncio.bem_arquivos && this.anuncio.bem_arquivos.length > 0
              ? this.arquivosUrl + this.anuncio.bem_arquivos[0]
              : null
        })
        .catch(error => this.notificacao('erro', error))
    },

    getAgendamentos () {
      const search = { usuario_id: this.usuarioSelecionado.id, anuncio_id: this.anuncioId }
      const params = { buscaGrid: search, searchJoin: 'and' }

      this.modelAgendamento.getListagem({ params }).then((res) => {
        const r = res.dados ?? null
        this.buscadoAgendamento = true
        if (r) {
          this.delivery = r.find(e => e.tipo === 'Delivery')
          this.testeDrive = r.find(e => e.tipo === 'Teste Drive')
          this.videochamada = r.find(e => e.tipo === 'Videochamada')
        }
        if (this.delivery) {
          const d = this.menus.findIndex(e => e.tipo === 'Delivery')
          if (d >= 0) {
            this.menus[d].descricao = `Agendado para ${this.$options.filters.dateBr2(this.delivery.data_atendimento)} as ${this.$options.filters.hora(this.delivery.data_atendimento)}`
          }
        }

        if (this.testeDrive) {
          const t = this.menus.findIndex(e => e.tipo === 'Teste Drive')
          if (t >= 0) {
            this.menus[t].descricao = `Agendado para ${this.$options.filters.dateBr2(this.testeDrive.data_atendimento)} as ${this.$options.filters.hora(this.testeDrive.data_atendimento)}`
          }
        }

        if (this.videochamada) {
          const v = this.menus.findIndex(e => e.tipo === 'Videochamada')
          if (v >= 0) {
            this.menus[v].descricao = `Agendado para ${this.$options.filters.dateBr2(this.videochamada.data_atendimento)} as ${this.$options.filters.hora(this.videochamada.data_atendimento)}`
          }
        }
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },

    voltar () {
      this.$router.go(-1)
    },

    irRota (rota) {
      this.$router.push(rota)
      // window.location.href = routeData.href
      // let routeData = this.$router.resolve(rota)
      // window.location.href = routeData.href
    }
  }
}
